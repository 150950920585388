//Path: src/components/NoteList.js
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../axiosInstance";
import moment from "moment";

const NoteList = () => {
  const [notes, setNotes] = useState([]);
  const [filters, setFilters] = useState({
    showDrafts: false,
    startDate: '',
    endDate: '',
    keyword: '',
  });

  
  useEffect(() => {
    axiosInstance
      .get("/api/notes")
      .then((response) => {
        // Sort notes by date
        const sortedNotes = response.data.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );
        setNotes(sortedNotes);
      })
      .catch((error) => console.error(error));
  }, []);

  const filteredNotes = notes.filter((note) => {
    if (filters.showDrafts && !note.draft) {
      return false; // Filtrar apenas rascunhos
    }
    if (filters.startDate && moment(note.date).isBefore(filters.startDate)) {
      return false; // Filtrar por data inicial
    }
    if (filters.endDate && moment(note.date).isAfter(filters.endDate)) {
      return false; // Filtrar por data final
    }
    if (filters.keyword && !note.title.toLowerCase().includes(filters.keyword.toLowerCase())) {
      return false; // Filtrar por palavra-chave no título
    }
    return true; // Exibe a nota se passar em todos os filtros
  });
  

  const handleDelete = (id) => {
    axiosInstance
      .delete(`/api/notes/${id}`)
      .then(() => setNotes(notes.filter((note) => note._id !== id)))
      .catch((error) => console.error(error));
  };

  return (
    <div className="max-w-2xl mx-auto mt-8">
      <h1 className="text-2xl font-bold mb-4">Notes</h1>
      <Link 
        to="/gallery"
        className="inline-block mb-4 px-4 py-2 mr-5 bg-purple-500 text-white rounded">
        Gallery
      </Link>
      <Link
        to="/create"
        className="inline-block mb-4 px-4 py-2 bg-green-500 text-white rounded"
      >
        Create New Note
      </Link>

      <div className="mb-6 p-4 bg-gray-100 rounded">
        <h2 className="font-bold mb-2">Filters</h2>
        <div className="space-y-2">
          <label className="block">
            <input
              type="checkbox"
              checked={filters.showDrafts}
              onChange={() =>
                setFilters({ ...filters, showDrafts: !filters.showDrafts })
              }
              className="mr-2"
            />
            Show Drafts Only
          </label>
          <label className="block">
            Start Date:
            <input
              type="date"
              value={filters.startDate}
              onChange={(e) =>
                setFilters({ ...filters, startDate: e.target.value })
              }
              className="ml-2 p-1 border rounded"
            />
          </label>
          <label className="block">
            End Date:
            <input
              type="date"
              value={filters.endDate}
              onChange={(e) =>
                setFilters({ ...filters, endDate: e.target.value })
              }
              className="ml-2 p-1 border rounded"
            />
          </label>
          <label className="block">
            Keyword:
            <input
              type="text"
              value={filters.keyword}
              onChange={(e) =>
                setFilters({ ...filters, keyword: e.target.value })
              }
              className="ml-2 p-1 border rounded"
            />
          </label>
        </div>
      </div>
      
      {filteredNotes.length === 0 ? (
        <p className="text-center text-gray-500">No notes match the filters.</p>
      ) : (
        <ul className="space-y-4">
          {filteredNotes.map((note, index) => (
          <li key={note._id} className="p-4 bg-gray-100 rounded shadow">
            <div className="flex justify-between items-center">
              <div>
                <h2 className="text-lg font-semibold">
                  ID: {index + 1} - {note.title.slice(0, 25)}
                  {note.title.length > 25 && "..."}
                </h2>
                <p>{note.draft ? "Draft" : "Published"}</p>
                <p>
                  Date: {moment(note.date, "YYYY-MM-DD").format("DD/MM/YYYY")}
                </p>
                <p>
                  Lastmod:{" "}
                  {moment(note.lastmod, "YYYY-MM-DD").format("DD/MM/YYYY")}
                </p>
                <div>
                  <h3 className="font-semibold">Repairs:</h3>
                  {note.repairs.map((repair, repairIndex) => (
                    <div key={repairIndex} className="ml-4">
                      <p>Problem: {repair.problem}</p>
                      <p>Solutions:</p>
                      <ul className="list-disc list-inside">
                        {repair.solutions.map((solution, solutionIndex) => (
                          <li key={solutionIndex}>{solution}</li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
                <div>
                  <h3 className="font-semibold">Repair Tips:</h3>
                  <ul className="list-disc list-inside ml-4">
                    {note.repairTips.map((tip, tipIndex) => (
                      <li key={tipIndex}>{tip}</li>
                    ))}
                  </ul>
                </div>
                <div>
                  <h3 className="font-semibold">Downloads:</h3>
                  {note.downloads.map((download, downloadIndex) => (
                    <a
                      key={downloadIndex}
                      href={download.file}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="block text-blue-500 underline"
                    >
                      {download.file}
                    </a>
                  ))}
                </div>
              </div>
              <div className="space-x-2">
                <Link
                  to={`/edit/${note._id}`}
                  className="px-4 py-2 bg-yellow-500 text-white rounded"
                >
                  Edit
                </Link>
                <button
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you want to delete this note?"
                      )
                    ) {
                      handleDelete(note._id);
                    }
                  }}
                  className="px-4 py-2 bg-red-500 text-white rounded"
                >
                  Delete
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>
    )}
    </div>
  );
};

export default NoteList;

import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://pedro-api.armando.com.br/",
});

// Adiciona um interceptor para lidar com erros 401
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Redireciona para a página de login
      window.location.href = "/login"; // Garante o redirecionamento mesmo fora de componentes React
    }
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("authToken");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default axiosInstance;

import { useState, useEffect } from "react";
import axiosInstance from "../axiosInstance";

const portalsite = "https://eletronicox.com/img/";

const AllGallery = () => {
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    axiosInstance
      .get("/api/notes")
      .then((response) => {
        setNotes(response.data);
      })
      .catch((error) => console.error(error));
  }, []);

  const handleDeleteImage = (noteId, imageName) => {
    axiosInstance
      .patch(`/api/notes/${noteId}/delete-image`, { imageName })
      .then((response) => {
        console.log("Image deleted successfully:", response.data);
        // Update the state to reflect the change
        setNotes((prevNotes) =>
          prevNotes.map((note) =>
            note._id === noteId
              ? { ...note, images: response.data.updatedImages }
              : note
          )
        );
      })
      .catch((error) => {
        console.error("Error deleting image:", error);
      });
  };

  return (
    <div className="max-w-5xl mx-auto mt-8">
      <h1 className="text-2xl font-bold mb-6 text-center">All Notes Gallery</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2">
        {notes.map((note) => (
          <div key={note._id} className="p-4 bg-gray-100 rounded shadow">
            <h2 className="text-base font-semibold mb-2">{note._id}</h2>
            <h2 className="text-lg font-semibold mb-2">{note.title}</h2>
            <div className="grid grid-cols-2 gap-2">
              {note.images.map((image, index) => (
                <div key={index} className="relative group">
                <a href={`${portalsite}${note._id}/${image}`} target="_blank" rel="noreferrer">
                  <img
                    src={`${portalsite}${note._id}/${image}`}
                    alt={`IMG ${index + 1}`}
                    className="w-full h-64 object-cover rounded"
                  />
                </a>
                  <button
                    onClick={() => handleDeleteImage(note._id, image)}
                    className="absolute top-2 right-2 px-2 py-1 bg-red-500 text-white text-sm rounded hidden group-hover:block"
                  >
                    Delete
                  </button>
                </div>
              ))}
            </div>
            {note.images.length === 0 && (
              <p className="text-sm text-gray-500">No images available.</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllGallery;
